import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CustomCssTransition from './CustomCssTransition';

const useStyles = makeStyles({

  default: {
    transform: 'scale(0.7)',
    opacity: 0,
  },
  transitions: props => ({
    '&.entering': {
      transition: `all ${props.entryDuration}ms ease-out`,
      transform: 'scale(1)',
      opacity: 1,
    },
    '&.entered': {
      transform: 'scale(1)',
      opacity: 1,
    },
    '&.exiting': {
      transition: `all ${props.exitDuration}ms ease-in`,
      transform: 'scale(0.7)',
      opacity: 0,
    },
    '&.exited': {
      transform: 'scale(0.7)',
      opacity: 0,
    },
  }),

})

//////////////////////////

const defaultProps = {
  duration: 250,
  id: "zoomInOut",
};

export const TransitionZoomInOut = props => {
  
  const {id} = props;

  //////
  
  return (
    <CustomCssTransition
      {...props}
      id = {id}
      useStyles = {useStyles}
      >
      {props.children}
    </CustomCssTransition>
  );

};

TransitionZoomInOut.defaultProps = defaultProps;
export default TransitionZoomInOut;
