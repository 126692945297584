import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CustomCssAnimation from './CustomCssAnimation';

//////////////////////////

const useStyles = makeStyles( theme => ({

  default: {
    transform: 'scale(1)',
  },

  '@keyframes shake': {
    '0%': {
      transform: 'rotate(0deg)',
      animationTimingFunction: 'ease-in-out',
    },
    '25%': {
      transform: 'rotate(5deg)',
      animationTimingFunction: 'ease-in-out',
    },

    '75%': {
      transform: 'rotate(-5deg)',
      animationTimingFunction: 'ease-in-out',
    },
    '100%': {
      transform: 'rotate(0deg)',
      animationTimingFunction: 'ease-in-out',
    },
  },

  transitions: {
    '&.entering': {
      // NOTE: Duration isn't passed in here because the timing controls one single loop.
      // TODO: It should be made into a perfect division of duration though - perhaps use a speed prop as a coefficient
      animation: '$shake 200ms linear infinite',
    },
    '&.entered': {
      animation: '$shake 200ms linear infinite',
    },
    '&.exiting': {
      transform: 'translate(0)'
    },
    '&.exited': {
      transform: 'translate(0)'
    },
  },

}))

//////////////////////////

const defaultProps = {
  delay: 0,
  duration: 750,
  id: 'shake',
}

export const AnimateShake = props => {

  const {id} = props;

  //////
  
  return (

    <CustomCssAnimation
      {...props}
      id = {id}
      useStyles = {useStyles}
    >
      {props.children}
    </CustomCssAnimation>
  );

};

AnimateShake.defaultProps = defaultProps;
export default AnimateShake;
