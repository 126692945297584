/*
 * CustomCssTransition
 *
 * Use this to create transition wrappers that are designed to start in one pose and end in another.
 * The transition is run by apply CSS classes at various timings - The CSS classes being passed in as a useStyles object.
 * Transitions built with this component will play their entry animation immediately on mount.
 */

import PropTypes from 'prop-types';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import clsx from 'clsx';
import { Transition } from 'react-transition-group';

//////////////////////////

// Process the props to fill in defaults before use anywhere
const processProps = props => {

  // Use for shorthand of only an entry animation
  const {
    enterFromInitialStyle, // Bool: set true to force a reset to default when starting enter animation
    delay = 250,  // Default delay to help items transitioning on mount from going too early
    duration = 250,
    asGlimpse = false,
    children,
    useStyles,
  } = props;

  // use when setting both entry and exit animation
  const {
    entryDelay = delay,
    entryDuration = duration,
    exitDelay = delay,
    exitDuration = duration,
  } = props;

  return {
    delay,
    duration,
    asGlimpse,
    children,
    useStyles,
    entryDelay,
    entryDuration,
    exitDelay,
    exitDuration,
    enterFromInitialStyle,
  }

}

//////////////////////////

const propTypes = {
  asGlimpse: PropTypes.bool,
  delay: PropTypes.number,
  duration: PropTypes.number,
  entryDelay: PropTypes.number,
  entryDuration: PropTypes.number,
  exitDelay: PropTypes.number,
  exitDuration: PropTypes.number,
  children: PropTypes.any.isRequired,
  useStyles: PropTypes.func.isRequired,
};

export const CustomCssTransition = props => {

  const [isActive, setIsActive] = useState(false);
  const [forceInitialStyle, setForceInitialStyle] = useState(props.enterFromInitialStyle);
  const {
    asGlimpse,
    children,
    useStyles,
    entryDelay,
    entryDuration,
    exitDelay,
    exitDuration,
  } = processProps(props);

  // pass values into passed in "makeStyles" function to process CSS
  const styles = useStyles({
    entryDuration,
    exitDuration,
  });
  
  let timings = {
    enter: entryDuration,
    exit: exitDuration,
  }


  // Set up initial animation on first render
  // NOTE: The docs refer to "appear" as a bool (along with "in") to set true if the transition is to occur immediately on mounting, however, this doesn't seem to help. So useEffect has been used (but is necessary for delayed timing anyway)
  useEffect(() => {

    // Set when to run entry anim
    setTimeout(() => {
      setForceInitialStyle(false)
      setIsActive(true);
    }, entryDelay);

    // Set when to run exit anim (if passed in)
    if(asGlimpse === true) {
      queueExitAnim(entryDelay + entryDuration + exitDelay);
    }

  }, [asGlimpse, entryDelay, entryDuration, exitDelay]);


  /**
   * Start enter animation immediately (but reset to default first if required)
   */
  const startEnterAnim = () => {
    // Only start anim if it's not in an entered or entering
    if( !isActive ) {
      if( props.enterFromInitialStyle ) {
        // Reset the styles back to the default immediately before starting enter animation a moment later
        setForceInitialStyle(true);
        setTimeout( () => {
          setForceInitialStyle(false);
          setIsActive(true);
        }, 10);

      } else {
        // Start enter animation animating from whatever state it's currently in
        setIsActive(true);
      }
    }
  }

  /**
   * Start exit animation immediately
   */
  const startExitAnim = () => {
    setIsActive(false);
  }

  /**
   * Start exit animation after delay
   * @param {int} delay 
   */
  const queueExitAnim = (delay) => {
    setTimeout(() => setIsActive(false), delay);
  }

  // Define controls
  const enter = () => {
    startEnterAnim();
  }
  const exit = () => {
    startExitAnim();
  }
  const toggle = () => {
    if(isActive) {
      startExitAnim();
    } else {
      startEnterAnim();
    }
  }
  const glimpse = () => {
    // Start the entry transition immediately
    startEnterAnim();
    // Set the exit transition to start after appropriate delay
    const { entryDelay, entryDuration, exitDelay } = processProps(props);
    queueExitAnim( entryDelay + entryDuration + exitDelay );
  }

  // FIXME: This runs everytime. It should only run on mount.
  // Link to controls
  if(props.setControls) {
    let id = props.id || "custom";
    // Pass key and controls back to calling component
    props.setControls(id, {
      enter,
      exit,
      toggle,
      glimpse
    });
  }
  

  //////

  return (
    <Transition
      in = {isActive}
      timeout = {timings}
    >
    {state => (
      <div
        className = {clsx(
          styles.default,
          styles.transitions,
          forceInitialStyle ? "initial" : state
        )}
        style = {{
          display: 'fit-content'
        }}
      >
        {children}
      </div>
    )}
    </Transition>
  );

};

CustomCssTransition.propTypes = propTypes;
export default CustomCssTransition;