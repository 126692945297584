import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CustomCssTransition from './CustomCssTransition';

//////////////////////////

const useStyles = makeStyles({

  default: {
  },
  transitions: props => ({
    '&.initial': {
      transform: 'scale(0.7)',
      opacity: 0,
    },
    '&.entering': {
      transition: `all ${props.entryDuration}ms ease-out`,
      transform: 'scale(1)',
      opacity: 1,
    },
    '&.entered': {
      transform: 'scale(1)',
      opacity: 1,
    },
    '&.exiting': {
      transition: `all ${props.exitDuration}ms ease-in`,
      transform: 'scale(1.3)',
      opacity: 0,
    },
    '&.exited': {
      transform: 'scale(1.3)',
      opacity: 0,
    },
  }),

})

//////////////////////////

const defaultProps = {
  duration: 250,
  id: "zoomIn",
};

export const TransitionZoomIn = props => {
  
  const {id} = props;

  //////
  
  return (
    <CustomCssTransition
      {...props}
      id = {id}
      enterFromInitialStyle = {true}
      useStyles = {useStyles}
      >
      {props.children}
    </CustomCssTransition>
  );

};

TransitionZoomIn.defaultProps = defaultProps;
export default TransitionZoomIn;
